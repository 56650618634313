export default {
  //A
  "All rights reserved": "Todos los derechos reservados",
  Admin: "Administrador",
  Accepted: "Aceptado",
  Arrived: "Conductor llegó",
  "About the panel": "Sobre el panel",
  "Adapted to your needs": "Nos adaptamos a tus necesidades",
  "Arrange trips for your clients": "Agenda viaje para tus clientes",
  Additionally: "Adicionalmente",
  "Also driver's availability near the origin point of the trip":
    "También se mostrará la disponibilidad cercana al punto de origen del viaje de conductores",
  ACTION: "ACCIÓN",
  ACCIÓN: "ACCIÓN",
  Apply: "Aplicar",
  "Available balance": "Balance",
  AMOUNT: "MONTO",
  "AGREGADO/RESTADO": "AGREGADO/RESTADO",
  "ADD/CUT": "AGREGADO/RESTADO",
  "Add to payroll": "Agregar a nómina",
  Active: "Activo",
  Activo: "Activo",
  "Activate overdraft": "Activar sobregiro",
  "Activate user": "Activar usuario",
  Approved: "Aprobado",
  All: "Todos",
  Assignations: "Asignaciones",
  "Additionally, our monitoring team is always at willingness to deal with any incident":
    "Adicionalmente nuestro equipo de monitoreo está siempre a disposición para atender cualquier incidencia",
  "AGREGADO/RESTADO (USUARIO)": "AGREGADO/RESTADO (USUARIO)",
  "ADD/CUT (USER)": "AGREGADO/RESTADO (USUARIO)",
  "Also driver's availability near the origin point of the parcel":
    "También se mostrará la disponibilidad cercana al punto de origen del viaje de conductores",
  Accept: "Aceptar",
  Approve: "Aprobar",
  and: "y",
  automated_payments: "Recargas automatizadas",
  automated_zelle: "Zelle automatizado",
  automated_pago_movil: "Pago móvil automatizado",
  api_credentials_generate_token: "Generar token",
  api_credentials_is_allowed_corporate_api_partner:
    "Acceso global a API partners corporativos permitido",
  api_credentials_is_corporate_api_partner:
    "Acceso particular a API partners corporativos permitido",
  api_credentials_is_allowed_corporate_shipment_api:
    "Acceso particular a envíos de API partners corporativos permitido",
  api_credentials: "Credenciales",
  api_credentials_tooltip:
    "En esta vista puedas revisar y editar tus credenciales de la API",
  api_credentials_active_api: "Permisos y accesos activos de la API",
  api_credentials_active_api_tooltip:
    "En esta sección puedes revisar permisos y accesos activos de la API",
  api_permissions_table_header: "Permiso",
  api_active_table_header: "Activo",
  api_credentials_is_allowed_corporate_api_partner_tooltip:
    "Este permiso indica si Ridery ha dado accesso a su API a sus API Partners",
  api_credentials_is_corporate_api_partner_tooltip:
    "Este permiso indica si el Panel Corporativo tiene acceso particual a la API de Ridery para API Partners",
  api_credentials_is_allowed_corporate_shipment_api_tooltip:
    "Este permiso indical si el Panel Corporativo tiene acceso particular a la API de Ridery para API Partners para Envíos",
  api_credentials_token: "Token",
  api_credentials_token_tooltip:
    "En esta sección puedes revisar y generar un token utilizado para acceder a la API para API Partners de Ridery",
  api_credentials_token_label: "Token para autorización de API",
  api_credentials_webhook_title: "Webhook",
  api_credentials_webhook_title_tooltip:
    "En esta sección puedes establecer un Webhook para recibir notificaciones de cambios de status de viajes/envíos que hayas realizado siendo API Partner",
  api_credentials_send_webhook: "Enviar Webhook",
  api_credentials_send_webhook_deactivate: "Desactivar Webhook",
  api_documentation: "Documentación",
  api_documentation_tooltip:
    "En esta vista puedes revisar la documentación para API Partners",
  //B
  Basic: "Básico",
  Balance: "Balance",
  "Back to the panel": "Volver al panel",
  By: "Por",
  "Book you work trips": "Agenda tus viajes corporativos",
  "Book a demonstration with our team":
    "Agenda una demostración con nuestro equipo",
  "Balance top-up request": "Solicitud de recarga de balance",
  "By admin": "Por admin",
  BALANCE: "SALDO",
  "By clicking here you can refresh the map":
    "Al clickear acá puedes refrescar el mapa",
  "By clicking you can refresh the nearby providers in the map":
    "Al clickear acá puedes refrescar los conductores cercanos en el mapa",
  Brand: "Marca",
  "BALANCE INICIAL": "BALANCE INICIAL",
  "INITIAL BALANCE": "BALANCE INICIAL",
  "BALANCE FINAL": "BALANCE FINAL",
  "FINAL BALANCE": "BALANCE FINAL",
  "BALANCE INICIAL (CORPORATIVO)": "BALANCE INICIAL (CORPORATIVO)",
  "INITIAL BALANCE (CORPORATE)": "BALANCE INICIAL (CORPORATIVO)",
  "BALANCE FINAL (CORPORATIVO)": "BALANCE FINAL (CORPORATIVO)",
  "FINAL BALANCE (CORPORATE)": "BALANCE FINAL (CORPORATIVO)",
  bulk: {
    unitary: "Unitariamente",
    bulk: "En lote",
  },
  //C
  "Create a new Trip": "Crear un nuevo viaje",
  Corporate: "Corporativo",
  clear: "clear",
  Completed: "Completado",
  Cancelled: "Cancelado",
  Coming: "En camino",
  "Cancelled by user": "Cancelado por usuario",
  "Corporate Panel": "Panel Corporativo",
  "Corporate Panels": "Paneles corporativos",
  "Corporate Panels Totals": "Total paneles corporativos",
  Contact: "Contacto",
  "Control your spending": "Controla tus gastos",
  Contactverb: "Contactar",
  "Contact form": "Formulario de contacto",
  "Can I book a trip or make reservations for another person?":
    "¿Puedo pedir un viaje o hacer reservas para una persona que no esté en mi panel?",
  "Create trip": "Crear viaje",
  "Current and scheduled trips": "Viajes en curso y programados",
  CONDUCTOR: "CONDUCTOR",
  COST: "COSTO",
  COSTO: "COSTO",
  "Cancel trip": "Cancelar viaje",
  Cancel: "Cancelar",
  "Charge of this trip": "Cargo del viaje",
  "Charge of this parcel": "Cargo del envío",
  Card: "Tarjeta",
  "CORREO ELECTRÓNICO": "CORREO ELECTRÓNICO",
  "Contact corporativo@ridery.app requesting the invoice of the recharge previously made, or failing that, to the fill out the payment form select ''YES'' in the field of invoice.":
    "Escribe un correo a corporativo@ridery.app solicitando la factura de la recarga previamente realizada, o en su defecto, al llenar el formulario de pago selecciona ''SI'' en el campo de factura.",
  "Create a new Parcel": "Crear un nuevo envío",
  "Current and scheduled parcel": "Envíos en curso y programados",
  Clear: "Limpiar",
  copy_trip_details: "Copiar enlace de detalles del viaje",
  copy_trip_details_success: "Detalles del viaje copiados exitósamente",
  copy_parcel_details: "Copiar enlace de detalles del envío",
  copy_parcel_details_success: "Detalles del envío copiados exitósamente",
  CorporatePanels: "Paneles corporativos",
  corporate_master_assign: "Asignación panel master",
  CorporatePanel: "Panel corporativo",
  contact_us: "Contáctanos",
  copy_to_clipboard: "Copiar al portapapeles",
  copy_to_clipboard_success: "Copiado al portapapeles",
  Credentials: "Credenciales",
  //D
  Dashboards: "Principal",
  "Destination address": "Dirección de destino",
  "Date and Time": "Fecha y Hora",
  "Dirección de origen": "Dirección de origen",
  "Dirección de destino": "Dirección de destino",
  DISTANCIA: "DISTANCIA",
  DURACIÓN: "DURACIÓN",
  "DATE AND TIME": "FECHA Y HORA",
  "DIRECCIÓN DE ORIGEN": "DIRECCIÓN DE ORIGEN",
  "DIRECCIÓN DE DESTINO": "DIRECCIÓN DE DESTINO",
  "DESTINATION ADDRESS": "DIRECCIÓN DE DESTINO",
  "Do you want to cancel the trip?": "¿Quiere cancelar el viaje?",
  "Date range": "Rango de fechas",
  "Deactivate overdraft": "Desactivar sobregiro",
  "Date issued": "Fecha emitida",
  "Date paid": "Fecha abonada",
  Discount: "Descuento",
  Details: "Detalles",
  "Driver's app certification": "Examen sobre uso correcto de la plataforma",
  Delete: "Eliminar",
  "Driver Waiting": "Conductor esperando",
  Documentation: "Documentación",
  //E
  Email: "Correo electrónico",
  "Enter your email and we'll send you instructions to reset your password":
    "Coloca tu correo electrónico y te enviaremos las instrucciones para restablecer tu contraseña",
  "Email sent": "Correo electrónico enviado",
  "Enter your new password": "Coloca tu nueva contraseña",
  "Error creating trip": "Error creando viaje",
  Export: "Exportar",
  EMAIL: "CORREO ELECTRÓNICO",
  "Eliminate user": "Eliminar usuario",
  "Efficiency in the execution of your budget.":
    "Garantiza eficiencia en la ejecución de tu presupuesto.",
  //F
  "Forgot Password?": "¿Olvidó su contraseña?",
  FINANCIAL: "FINANZAS",
  FAQ: "Preguntas y Respuestas",
  FAQ2: "Preguntas frecuentes",
  "Fecha y hora": "Fecha y hora",
  "FECHA Y HORA": "FECHA Y HORA",
  "Find your workers inside Ridery's app to add them to your payroll":
    "Find your workers inside Ridery's app to add them to your payroll",
  "For your safety": "Por tu seguridad",
  "For your comfort": "Para tu comodidad",
  "For increased efficiency": "Para mayor efectividad",
  fullName: "Nombre completo",
  //G
  GoBack: "Volver",
  "Get quote": "Cotizar",
  "Get quick quote": "Cotización rápida",
  "Go to page": "Ir a la página",
  "Good Morning!": "¡Buenos días!",
  "Good Afternoon!": "¡Buenas tardes!",
  "Good Evening!": "¡Buenas noches!",
  //H
  History: "Historial",
  "History ": "Historial ",
  "How is a trip's fare calculated?": "¿Cómo se calcula el precio de un viaje?",
  "How do I pay for my business trips?": "¿Cómo pago los viajes de mi empresa?",
  "How does Ridery take care of the safety of its passengers?":
    "¿Cómo cuida Ridery la seguridad de sus pasajeros?",
  "How do I receive invoices for my company's trips?":
    "¿Cómo recibo facturas de los viajes de mi empresa?",
  "How can I control my payroll expenses?":
    "¿Cómo puedo controlar los gastos de mi nómina?",
  "How to request the invoice prior to recharging?":
    "¿Cómo solicitar la factura previa a la recarga?",
  "How does Ridery take care of the safety of its passengers?":
    "¿Cómo cuida Ridery la seguridad de sus pasajeros?",
  hide_user_panel: "Ocultar panel de usuario",
  show_user_panel: "Mostrar panel de usuario",
  //I
  Inmediate: "Inmediato",
  "Invalid credentials": "Credenciales inválidas",
  "In this view you can visualize the trips in course on the map":
    "En esta vista puedes visualizar los viajes en curso en el mapa",
  "In this map view you can visualize the waypoints origin and destination":
    "En esta vista de mapa puedes visualizar los puntos de origen y destino",
  "In this form you can create a new trip for now or scheduled":
    "En este formulario puedes crear un nuevo viaje para ahora o programado",
  "If you need to adjust coordinates for a trip you can use the source and destination pointers for that purpose":
    "Si necesitas ajustar coordenadas para un viaje puedes tilizar los punteros de origen y destino para ese fin",
  "In this table you can consult all current and scheduled trips associated with your corporate panel":
    "En esta tabla puedes consultar todos los viajes en curso y programados asociados a tu panel corporativo",
  ID: "ID",
  "ID USUARIO": "ID USUARIO",
  Inactive: "Inactivo",
  Invoice: "Nota de servicio",
  "Invoice to": "Nota de servicio para",
  "in a": "de manera",
  "Ideal balance between cost control and self-management.":
    "Obtén el balance ideal entre control de gastos y autogestión.",
  "In this view you can visualize the parcels in course on the map":
    "En esta vista puedes visualizar los envíos en curso en el mapa",
  "In this form you can create a new parcel for now or scheduled":
    "En este formulario puedes crear envíos instantáneos o programados",
  "If you need to adjust coordinates for a parcel you can use the source and destination pointers for that purpose":
    "Si necesitas ajustar coordenadas para un envío puedes tilizar los punteros de origen y destino para ese fin",
  "in corporate panels": "en paneles corporativos",
  //Q
  "quick, confortable and secure way": "rápida, cómoda y segura",
  //L
  Login: "Iniciar Sesión",
  Logout: "Cerrar Sesión",
  Landing: "Bienvenido",
  "Last name": "Apellido",
  Link: "Enlace",
  "Live monitoring, accompaniment and assignment 24 hours a day of the day, 7 days a week with a response time from 3 to 7 minutes.":
    "monitoreamos, acompañamos y asignamos viajes 24/7, con un tiempo de respuesta entre 3 y 7 minutos aproximadamente.",
  "Lo sentimos, Ridery no presta servicio en esa dirección de origen":
    "Lo sentimos, Ridery no presta servicio en esa dirección de origen",
  //M
  "More information": "Más información",
  "Monitor your trips": "Monitorea tus viajes",
  MONTO: "MONTO",
  "Monitoreo (Viajes) Teléfono": "Monitoreo (Viajes) Teléfono",
  "Monitoreo (Viajes) Correo electrónico":
    "Monitoreo (Viajes) Correo electrónico",
  "must accept the request in Ridery's user app":
    "debe aprobar la solicitud en la aplicación de usuario de Ridery",
  Model: "Modelo",
  Multistop: "Multiparadas",
  multistop_quoted: "Multiparadas cotizadas",
  multistop_quoted_tooltip:
    "En esta vista puedes consultar las multiparadas cotizadas, aprobar o eliminar. Las multiparadas no aprobadas expiran 15 minutos antes de la hora programadas",
  multi_stop_new: "Nueva cotización de multiparada",
  multistop_delete_modal: "¿ Quieres eliminar la cotización ?",
  multistop_total_stop: "Total parada",
  multistop_time_stop: "Total tiempo",
  multistop_distance_stop: "Total distancia",
  multistop_new_map_tooltip:
    "En esta vista de mapa puedes consultar los puntos de multiparada",
  multiStopNew: "Nueva multiparada",
  multistop_add: "Agregar",
  multistop_add_tooltip:
    "En este formulario puedes ir agregando paradas a un viaje multiparada",
  multistop_stop: "Parada",
  multistop_origin: "Origen",
  multistop_full_1: "Has alcanzado el máximo de paradas permitidas",
  multistop_full_2: "Puedes consultar el listado y resumen de paradas",
  multistop_list: "Listado de paradas",
  multistop_list_tooltip:
    "En esta tabla puedes consultar el listado de paradas de un viaje multiparada",
  multistop_total_resume: "Resumen multiparada",
  multistop_total_resume_tooltip:
    "En esta tabla puedes consultar el resumen de la multiparada",
  multistop_total_quote: "Cotizar multiparada",
  multi_stop: "Multiparada",
  multistop_show_quote_title: "Detalle de cotización (Multiparada)",
  multistop_show_quote_title_tooltip:
    "En esta vista puedes observar el detalle de una cotización de una multiparada",
  multistop_approve: "Aprobar cotización de multiparada",
  multistop_map_view: "Mapa del Multiparada",
  multistop_quote_success: "Multiparada cotizada satisfactoriamente",
  MultistopShow: "Detalle de viaje multiparada",
  manual_top_up_request: "Recargas manuales",
  manual_top_up_pending_table: "Recargas manuales esperando validación",
  manual_top_up_pending_table_tooltip:
    "En esta vista puedes consultar tus recargas manuales esperando validación",
  manual_payments_pending_see_details: "Ver detalles",
  //N
  "New Trip": "NUevo Viaje",
  "Not corporate": "No corporativo",
  Name: "Nombre",
  "Nombre y apellido": "Nombre y apellido",
  "No driver": "Sin conductor",
  NAME: "NOMBRE",
  NOMBRE: "NOMBRE",
  Note: "Nota",
  "Not approved": "No aprobado",
  "NON FISCAL": "NO FISCAL",
  "Manage your trips": "Gestiona tus viajes",
  NOTA: "NOTA",
  "New Parcel": "Nuevo Envío",
  no: "No",
  //O
  "Origin address": "Dirección de origen",
  "Our clients": "Nuestros clientes",
  "Our sales team is capacitated to show you a demo with the whole potential of the panel":
    "Conoce a profundidad los beneficios que tenemos para tu empresa, colaboradores y clientes.",
  "Obligatory field": "Campo obligatorio",
  Operator: "Operadora",
  Overdraft: "Sobregiro",
  "ORIGIN ADDRESS": "DIRECCIÓN DE ORIGEN",
  "ORIGEN DE TRANSACCIÓN": "ORIGEN DE TRANSACCIÓN",
  OVERDRAFT: "SOBREGIRO",
  "Optimize your time": "Optimiza tu tiempo",
  "Our drivers must pass a selection process of 4 stages:":
    "Aseguramos un proceso robusto de certificación de conductores y vehículos, y adicionalmente, nuestro equipo de monitoreo está siempre a disposición para atender cualquier necesidad que se presente.",
  "Our operators are 24 hours for you! Communicate for the following means for any incident or inconvenience presented:":
    "¡Nuestros operadores están 24 horas para tí! Comunícate por los siguientes medios para cualquier incidencia o inconveniente presentado: ",
  "Our trips are calculated based on a fixed rate per fleet selected being able to have various surcharges (time of day, traffic conditions, etc) if applicable.":
    "Tomamos como base una tarifa fija por flota seleccionada, sobre la cual pueden aplicar variaciones dependiendo del horario, tráfico, clima, entre otros factores.",
  //P
  "Please sign-in to your account and manage your trips":
    "Inicia sesión en tu cuenta y administra tus viajes",
  Password: "Contraseña",
  Profile: "Perfil",
  "Please check your email": "Por favor revisa tu correo electrónico",
  PasswordConfirm: "Confirmar contraseña",
  "Password reset": "Contraseña restablecida",
  Provider: "Conductor",
  Payroll: "Nómina",
  PROVIDER: "CONDUCTOR",
  PHONE: "TELÉFONO",
  Print: "Imprimir",
  "Psychotechnical evaluation": "Evaluación psicotécnica integral",
  "Psycho-technical evaluation, vehicle certification (aesthetics and mechanical), up-to-date legal documentation and final exam of app approval":
    "Evaluación psicotécnica, certificación del vehículo (estética y mecánica), documentación legal al día y examen final de aprobación de la app",
  "Plate number": "Placa",
  provider_name: "Nombre de conductor",
  Phone: "Teléfono",
  PARCELS: "ENVÍOS",
  "Parcel Type": "Tipo de envío",
  "Parcel now": "Instantáneo",
  "Parcel responsible user": "Usuario responsable del envío",
  "Parcel history": "Historial de envíos",
  "PARCEL ID": "ID DE ENVÍO",
  "ID DE ENVÍO": "ID DE ENVÍO",
  parcel_receives_tooltip: "Esta persona sería contactada por el conductor",
  parcel_receiver: "¿Quién recibe tu envío?",
  parcel_textarea: "Detalles adicionales del envío",
  parcel_textarea_error:
    "Detalles adicionales deben ser 150 caracteres o menos",
  parcel_create: "Crear envío",
  parcel_created: "Envío creado exitosamente",
  parcel_cancel: "Cancelar envío",
  parcel_cancel_modal: "¿Quieres cancelar el envío?",
  parcel_details: "Detalles de envío",
  parcel_details_table: "Quien recibe el envío",
  parcelHistory: "Historial de envíos",
  parcel_table_running_tooltip:
    "En esta tabla puedes consultar todos los envíos en curso y programados asociados a tu panel corporativo",
  Parcels: "Envíos",
  ParceltooltipHistoryUser:
    "En esta vista puedes consultar los envíos realizados del usuario con el panel corporativo",
  parcel_more_info: "Políticas",
  ParcelGuide: "Políticas de envío",
  parcel_package_size: "Tamaño del paquete",
  parcel_package_mass: "Kilos permitidos",
  parcel_package_examples: "Puedes enviar este tipo de paquetes:",
  parcel_view_map_title: "Mapa del envío",
  parcel_view_map_completed_tooltip:
    "En esta vista puedes visualizar el recorrido aproximado de un envío completado entre punto de origen y destino",
  parcel_view_map_completed_tooltip2:
    "En esta vista puedes visualizar el estado en mapa de un envío en curso (si aplica) y los puntos de origen y destino",
  parcel_cancelled: "Envío cancelado exitosamente",
  parcel_cancelled_error: "Error cancelando envío",
  parcel_multistop_add_tooltip:
    "En este formulario puedes ir agregando paradas a un envío multiparada",
  parcel_multistop_list_tooltip:
    "En esta tabla puedes consultar el listado de paradas de un envío multiparada",
  person_is_contact: "Esta persona será contactada por el conductor",
  pago_movil_automatizado_tooltip:
    "Recarga tu balance corporativo con nuestro nuevo método de pago móvil automatizado",
  pago_movil_new: "Nuevo",
  ParcelMultistop: "Envíos multiparadas",
  parcelMultiStopNew: "Nuevo envío multiparadas",
  parcelMultiStopShow: "Detalle de envío multiparadas",
  parcel_continue: "Continuar",
  parcel_i_send: "Envía",
  parcel_i_receive: "Recibe",
  parcel_textarea_1_send: "Instrucciones en el origen",
  parcel_textarea_1_receive: "Instrucciones en el destino",
  parcel_data_person_send: "Datos de la persona que recibe",
  parcel_data_person_receive: "Datos de la persona que entrega",
  parcel_user_full_name: "Nombre y apellido",
  parcel_textarea_2_send: "Instrucciones en el destino",
  parcel_textarea_2_receive: "Instrucciones en el origen",
  parcel_textarea_package: "Descripción del paquete",
  parcel_package: "Paquete",
  parcel_package_image: "Imagen del paquete",
  parcel_upload_image: "Cargar imagen",
  parcel_prohibited_items: "Artículos prohíbidos",
  parcel_prohibited_items_shipments: "en nuestros envíos",
  parcel_prohibited_fireamrs: "Armas de fuego",
  parcel_prohibited_substances: "Sustancias ilícitas",
  parcel_prohibited_cash: "Dinero en efectivo",
  parcel_prohibited_waste: "Basura Tóxica",
  parcel_prohibited_lingots: "Lingotes",
  parcel_prohibited_fireworks: "Fuegos artificiales",
  parcel_prohibited_insecticides: "Insecticidas",
  parcel_shipment_summary: "Resumen de envío",
  parcel_quote_origin: "Origen",
  parcel_not_defined_remittent: "No definido",
  parcel_image_error_size: "La imagen debe ser menor a 5mb",
  parcel_image_error_required: "La imagen es requerida",
  parcel_quote_sender: "Pesona que envía",
  parcel_quote_sender_phone: "Teléfono",
  parcel_quote_instructions_on_origin: "Instruccciones en el origen",
  parcel_quote_destination: "Destino",
  parcel_quote_receiver: "Persona que recibe",
  parcel_quote_recoiver_phone: "Teléfono",
  parcel_quote_instructions_on_destination: "Instruccciones en el destino",
  parcel_quote_package_photo: "Foto del paquete",
  parcel_quote_package_description: "Descripción del paquete",
  parcel_parcel: "Envío",
  //Q
  "Quick quote": "Cotización rápida",
  //R
  REQUESTS: "VIAJES",
  "Running trips": "Viajes en curso ",
  "Realtime map": "Mapa",
  "Reset your password": "Resestablezca su contraseña",
  "Results per page": "Resultados por página",
  "REMAINING BALANCE": "SALDO RESTANTE",
  "Ridery's Corporate team": "Equipo de Ridery Corporativo",
  "Ridery Contact": "Contacto Ridery",
  "Ridery Corporate": "Ridery Corporativo",
  "Request a ride in less than 10 minutes.":
    "Solicita cualquier traslado en menos de 10 minutos.",
  "Refresh table": "Refrescar tabla",
  "Refresh map": "Refrescar mapa",
  "Running parcels": "Envíos en curso",
  //S
  Scheduled: "Programado",
  Started: "Viaje iniciado",
  Send: "Enviar",
  "Service type": "Tipo de servicio",
  Search: "Buscar",
  "Search by...": "Buscar por...",
  STATUS: "STATUS",
  "SALDO RESTANTE": "SALDO RESTANTE",
  Status: "Status",
  SALDO: "SALDO",
  SOBREGIRO: "SOBREGIRO",
  "Simultaneously move your collaborators or clients.":
    "Mueve simultáneamente a tus colaboradores o clientes.",
  "Schedule a meeting with our team": "Agenda una reunión con nosotros",
  Sobredemanda: "Sobredemanda",
  Service: "Servicio",
  SERVICIO: "SERVICIO",
  special_trip: "Viaje especial",
  //T
  "Trip Type": "Tipo de Viaje",
  Telephone: "Teléfono",
  "Travel now": "Viajar ahora",
  "Tipo de viaje": "Tipo de viaje",
  "Tipo de usuario": "Tipo de usuario",
  "Tipo de servicio": "Tipo de servicio",
  "Trip created successfully": "Viaje creado exitosamente",
  "TRIP ID": "ID VIAJE",
  "Trip cancelled successfully": "Viaje cancelado con éxito",
  "Trip history": "Historial de viajes",
  tooltipHistory:
    "En esta vista puedes consultar el historial de viajes, filtrar y exportar .xls",
  tooltipBalance:
    "En esta vista puedes ver las diferentes operaciones sobre tu balance",
  "Transaction origin": "Origen de Transacción",
  "TRANSACTION ORIGIN": "ORIGEN DE TRANSACCIÓN",
  tooltipUsers1:
    "En esta vista puedes consultar tu nómina y realizar operaciones básicas de administración como:",
  tooltipCorporatePanels1:
    "En esta vista puedes consultar tu nómina de panales corporativos y realizar operaciones básicas de administración como:",
  tooltipUsers2:
    "Agregar usuarios, administarar sobregiros, agregar balance, eliminar usuarios",
  tooltipCorporatePanels2: "Agregar balance o ver paneles corporativos",
  TELÉFONO: "TELÉFONO",
  "Thank you for choosing us": "Gracias por preferirnos",
  Tax: "Impuesto",
  "This receipt does not represent a fiscal invoice":
    "Esta nota de servicio no representa una factura fiscal",
  "Trip map": "Mapa del viaje",
  tooltipTripMap:
    "En esta vista puedes visualizar el recorrido aproximado de un viaje completado entre punto de origen y destino",
  tooltipTripMap2:
    "En esta vista puedes visualizar el estado en mapa de un viaje en curso (si aplica) y los puntos de origen y destino",
  "Trip ID": "ID viaje",
  Trips: "Viajes",
  tooltipUserAssign:
    "En esta vista puedes consultar las asignaciones realizadas al usuario desde el panel",
  "Through our toolset": "A través de nuestras herramientas",
  "To enter our fleet drivers must pass a 4-stage selection process":
    "todo conductor de la flota Ridery pasa por un arduo proceso de certificación que contempla 4 etapas:",
  "Total users": "Total usuarios",
  "Trip in couse": "Viaje en curso",
  tooltipHistoryUser:
    "En esta vista puedes consultar los viajes realizados del usuario con el panel corporativo",
  "This input is autocomplete": "Este campo es autocompletado",
  tooltipHistoryParcel:
    "En esta vista puedes consultar el historial de envíos del panel",
  TripInfo: "Información",
  trip: {
    trip_resume: "Resumen de viaje",
  },
  topUps: "Recargas",
  tooltipTopUps:
    "Aquí puedes escoger un método de pago para hacer una recarga de balance corporativo",
  //U
  USERS: "USUARIOS",
  User: "Usuario",
  Users: "Usuarios",
  "User type": "Tipo de usuario",
  unique_id: "ID Viaje",
  user_first_name: "Nombre de usuario",
  "User name": "Nombre de usuario",
  USER: "USUARIO",
  USUARIO: "USUARIO",
  "User amount": "Monto usuario",
  "USER ID": "ID USUARIO",
  "Up-to-date legal documentation":
    " Comprobación de documentos legales al día",
  "User does not have sufficient balance or there isn't enough overdraft":
    "Usuario no posee saldo suficiente o no se dispone de sobregiro suficiente",
  user_wallet: "Saldo",
  user_found_db_ridery: "Usuario encontrado en la base de datos de Ridery",
  user_found_db_ridery_filling: "Rellenando automáticamente los campos",
  //V
  ViewMap: "Ver mapa",
  "View user": "Ver usuario",
  "View provider": "Ver conductor",
  "View map": "Ver mapa",
  "View invoice": "Ver nota de servicio",
  "Vehicle certification (aesthetics and mechanics)":
    "Revisión estética y mecánica del vehículo",
  Vehicle: "Vehículo",
  variable_tariffs: "Nuestras tarifas pueden variar",
  "View corporate panel": "Ver panel corporativo",
  //W
  "Welcome!": "¡Bienvenido!",
  Waiting: "En espera",
  "We've sent an email to": "Hemos enviado un correo electrónico a",
  "Welcome again": "Bienvenido de nuevo",
  "Waiting for approval": "Esperando aprobación",
  "We are the app that modernizes your business mobility":
    "Somos la app que moderniza la movilidad de tu empresa",
  "We have an extensive fleet of vehicles, ranging from the most economical to passenger buses in the whole national territory.":
    "contamos con una extensa flota de vehículos, desde una moto hasta vans de pasajeros dispuestas para trasladarte por todo el territorio nacional.",
  "We have the following means for you to contact us. Our team will gladly assist you.":
    "Nuestro equipo está dispuesto a asistir cualquier requerimiento. Disponemos principalmente de dos canales para atender tus consultas o dudas.",
  "What should I do if I file an incident with my app?":
    "¿Qué debo de hacer si presento una incidencia con mi app?",
  //Y
  "You can log back in": "Puedes volver a iniciar sesión",
  "You can create trips for your corporate users or for third-party users":
    "Puedes crear viajes para tus usuarios corporativos o para usuarios terceros",
  Yes: "Sí",
  "You've selected an invalid hour, previous to current time. Please select a different hour":
    "Has seleccionado una hora inválida, anterior a la actual. Por favor seleccione una hora distinta",
  "Yes! When requesting a trip through the Corporate Panel, select ''Non-Corporate User'', fill in the fields, you proceed to request the trip and that's it, your rider will arrive right away.":
    "¡Sí! Al momento de solicitar un viaje por el Panel Corporativo, selecciona ''Usuario No Corporativo'', llenas los campos, procedes a pedir el viaje y listo, tu rider llegará enseguida.",
  "You can also make quick quotes":
    "También es posible realizar cotizaciones rápidas",
  Year: "Año",
  "you may search corporate users by:":
    "Puedes buscar usuarios de tu nómina por:",
  "You can create parcel for your corporate users or for third-party users":
    "Tus usuarios corporativos van a ser responsables del envío",
  yes: "Sí",

  // HEADERS
  "LAST STOP": "ÚLTIMA PARADA",
  "ÚLTIMA PARADA": "ÚLTIMA PARADA",

  STOPS: "PARADAS",
  PARADAS: "PARADAS",

  SERVICE: "SERVICIO",
  SERVICIO: "SERVICIO",

  STOP: "PARADA",
  PARADA: "PARADA",

  "STOP COST": "COSTO DE PARADA",
  "COSTO DE PARADA": "COSTO DE PARADA",

  FEE: "RECARGO",
  RECARGO: "RECARGO",

  "TOTAL STOP": "TOTAL PARADA",
  "TOTAL PARADA": "TOTAL PARADA",

  ACTION: "ACCIÓN",
  ACCIÓN: "ACCIÓN",

  "STOP QUANTITY": "CANTIDAD PARADAS",
  "CANTIDAD PARADAS": "CANTIDAD PARADAS",

  "ESTIMATED TIME": "TIEMPO ESTIMADO",
  "TIEMPO ESTIMADO": "TIEMPO ESTIMADO",

  "ESTIMATED DISTANCE": "DISTANCIA ESTIMADA",
  "DISTANCIA ESTIMADA": "DISTANCIA ESTIMADA",

  "MULSTISTOP COST": "COSTO MULTIPARADA",
  "COSTO MULTIPARADA": "COSTO MULTIPARADA",

  "Download PDF": "Descargar PDF",
  "Service Note": "Nota de servicio",
  "Download Quote": "Descargar Cotización",
  "Service Quote to": "Cotización de servicio para",
  "Service Quote": "Cotización de servicio",
  SURGE: "SOBREDEMANDA",
  TOTAL: "TOTAL",
  "Note: This quote represents a referential value of the trip":
    "Nota: Esta cotización representa un valor referencial del viaje",
  "the value of the trip is subject to change":
    "el valor del viaje se encuentra sujeto a cambios",
  "PARADA FINAL": "PARADA FINAL",

  Add: "Agregar",
  Remove: "Eliminar",
  "Promo Code": "Cupón promocional",
  "Add Promo Code": "Agrega un cupón",
  "The promo code has expired or is invalid":
    "El código promocional ha expirado o es inválido",
  "Promo code applied successfully": "Código promocional aplicado con éxito",
  "Error getting estimated fare with promo code":
    "Error al obtener la tarifa estimada con el código promocional",

  "trip description": "Descripción del viaje",
  "TRIP DESCRIPTION": "DESCRIPCIÓN DEL VIAJE",
  "Add Description": "Agregar descripción",
  "Add trip description": "Añade una descripción al viaje",
  Completado: "Completado",
  "ID VIAJE": "ID VIAJE",
  DESCRIPTION: "DESCRIPCIÓN",

  "Exporting parcels": "Exportando envíos",
  "Exporting trips": "Exportando viajes",
  "You can download the export file":
    "Puedes descargar el archivo de exportación",
  expired: "expirado",
  "Processing pages for Excel file": "Procesando páginas para archivo Excel",
  "Processing page": "Procesando página",
  of: "de",
  "Sorry, there was an error": "Lo sentimos, hubo un error",
  Close: "Cerrar",
  "The export file has": "El archivo de exportación ha",
  Download: "Descargar",

  "Download PDF": "Descargar PDF",
  "Service Note": "Nota de servicio",
  "Download Quote": "Descargar Cotización",
  "Service Quote to": "Cotización de servicio para",
  "Service Quote": "Cotización de servicio",
  SURGE: "SOBREDEMANDA",
  TOTAL: "TOTAL",
  "Note: This quote represents a referential value of the trip":
    "Nota: Esta cotización representa un valor referencial del viaje",
  "the value of the trip is subject to change":
    "el valor del viaje se encuentra sujeto a cambios",
  "PARADA FINAL": "PARADA FINAL",

  "Exporting payments": "Exportando pagos",
  "Pago móvil automatizado": "Pago móvil automatizado",
  "FACTURA PERSONALIZADA": "FACTURA PERSONALIZADA",
  MONEDA: "MONEDA",
  TIPO: "TIPO",
  "FECHA ENVIADO": "FECHA ENVIADO",
  "FECHA DE RECARGA": "FECHA DE RECARGA",

  Total: "Total",
  DESCRIPCIÓN: "DESCRIPCIÓN",
  "Cancel Reason": "Razón de cancelación",
  "Select one of the following reasons to cancel this trip":
    "Selecciona una de las siguientes razones para cancelar este viaje",
  Confirm: "Confirmar",

  "Enter current password": "Ingresa contraseña actual",
  "Enter new password": "Ingresa nueva contraseña",
  "Confirm new password": "Confirmar nueva contraseña",
  "Incorrect password": "Contraseña incorrecta",
  "Change password": "Cambiar contraseña",
  "Passwords do not match": "La contraseña no coincide",
  Continue: "Continuar",
  "Your password has been saved successfully":
    "Tu contraseña ha sido guardada con éxito",
  "Passwords do not match": "La contraseña no coincide",
  Continue: "Continuar",

  "Passwords must match": "Las contraseñas deben coincidir",
  "Recover password": "Recuperar contraseña",
  "To recover your password, enter your email":
    "Para recuperar tu contraseña indica el correo electrónico",
  GOBACK: "VOLVER",
  "with instructions to reset your password. Check your inbox, including the spam folder":
    "con instrucciones para restablecer tu contraseña. Revisa tu bandeja de entrada, incluyendo la carpeta de spam",
  "Check your email": "Revisa tu correo",
  "Set the new password and its confirmation":
    "Establece la nueva contraseña y su confirmación",
  PasswordNewConfirm: "Confirmar nueva contraseña",
  "New Password": "Nueva contraseña",
  ResetPassword: "Restablecer contraseña",
  "The link has expired. Request a new link to reset your password":
    "El enlace ha expirado. Solicita un nuevo enlace para restablecer tu contraseña",
  "We're sorry!": "¡Lo sentimos!",
  "Back to the start": "Volver al inicio",
  "Your password has been saved successfully":
    "Tu contraseña ha sido guardada con éxito",
  "Error resetting password": "Error al restablecer la contraseña",
  "An error occurred, please try again later":
    "Ocurrió un error, intenta más tarde",
  "The email is not valid": "El correo no es válido",
  "Exporting payroll": "Exportando nómina",
  "Esperando aprobación": "Esperando aprobación",
  Inactivo: "Inactivo",
  NOTE: "NOTA",
  ForgotPassword: "Olvidé mi contraseña",
};
